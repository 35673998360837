
import ValidationMixin from "@/mixins/validation";
import {
  CategoryTypes,
  TicketCategory,
} from "@/store/modules/category/category.types";
import { DefaultState } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";

const categoryX = namespace("Category");

@Component({})
export default class EditCategory extends Mixins(ValidationMixin) {
  @categoryX.State(CategoryTypes.DELETE_CATEGORY_STATE)
  public deleteCategory!: DefaultState;

  @categoryX.State(CategoryTypes.CATEGORY_DATA)
  public ticketCategory!: TicketCategory;

  @categoryX.Mutation(CategoryTypes.SET_DELETE_CATEGORY_DIALOG)
  public setDeleteCategory!: (deleteCategory: boolean) => void;

  @categoryX.Action(CategoryTypes.DELETE_CATEGORY)
  public deleteTicketCategory!: () => Promise<void>;

  public updateCategoryStep = 1;

  public isValid = false;

  @Ref("form") public formRef!: Validator;

  closeDeleteCategoryDialog(): void {
    this.setDeleteCategory(false);
  }
}
